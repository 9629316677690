<template>
  <ion-tabs>
    <ion-router-outlet></ion-router-outlet>
    <ion-tab-bar slot="bottom">
      <ion-tab-button tab="timeReport" href="/time-reports">
        <ion-icon :icon="timeOutline"></ion-icon>
        <ion-label>Rapports</ion-label>
      </ion-tab-button>

      <ion-tab-button tab="allRentalCoupon" href="/listeBonLocation">
        <ion-icon :icon="readerOutline"></ion-icon>
        <ion-label>BL</ion-label>
      </ion-tab-button>

      <ion-tab-button tab="planning" href="/planning">
        <ion-img src="/assets/logo/LOCATIERS-ICONE_BLC.png"></ion-img>
        <ion-label>Missions</ion-label>
      </ion-tab-button>

      <ion-tab-button tab="settings" href="/settings">
        <ion-icon :icon="menuOutline"></ion-icon>
        <ion-label>Menu</ion-label>
      </ion-tab-button>
    </ion-tab-bar>
  </ion-tabs>
</template>

<script>
import {
  IonTabs,
  IonTabBar,
  IonRouterOutlet,
  IonTabButton,
  IonIcon,
  IonImg,
  IonLabel,
} from '@ionic/vue';
import { readerOutline, menuOutline, timeOutline } from 'ionicons/icons';

export default {
  name: 'BottomMenu',
  components: {
    IonTabs,
    IonTabBar,
    IonRouterOutlet,
    IonTabButton,
    IonIcon,
    IonImg,
    IonLabel,
  },
  setup() {
    return {
      readerOutline,
      menuOutline,
      timeOutline,
    };
  },
};
</script>

<style lang="scss" scoped>
ion-img {
  width: 28px;
}
</style>
