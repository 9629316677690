import { toastController } from '@ionic/vue';

const utils = {};

/**
 * Opening of a new tab with the journey to be made for the person
 */
utils.navigateTo = function navigateTo(latitude, longitude) {
  window.open(
    `http://maps.apple.com/?daddr=${latitude},${longitude}`,
    '_blank',
  );
};

utils.generateArray = function generateArray(size, increment = 1, start = 0) {
  const output = [];

  for (let i = start; i <= size + start; i++) {
    output.push(`${i * increment}`);
  }

  return output;
};

utils.onError = async err => {
  let messageError =
    "Problème lors de l'envoi des données, veuillez réessayer.";

  if (err && err.name === 'NetworkError') {
    messageError = 'Aucune connexion internet';
  } else if (err && err.response && err.response.data) {
    if (err.response.data.message) {
      messageError = err.response.data.message;
    }

    // in case of validation errors for example
    if (err.response.data.errors) {
      messageError = [];

      // add all errors message to our toast
      Object.values(err.response.data.errors).forEach(element => {
        messageError.push(element);
      });

      messageError = messageError.join('<br />');
    }
  }

  const toast = await toastController.create({
    message: messageError,
    duration: 15000,
    color: 'danger',
    position: 'top',
    cssClass: 'toast-rental-coupon',
    buttons: [
      {
        text: 'Fermer',
        role: 'cancel',
      },
    ],
  });
  await toast.present();
};

utils.loadIntercom = () => {
  /**
   * Minified code provided by Intercom
   * But Eslint "beautifies" it
   * + Modified to use directly intercomSettings.app_id
   */
  (function () {
    var w = window;
    var ic = w.Intercom;
    if (typeof ic === 'function') {
      ic('reattach_activator');
      ic('update', w.intercomSettings);
    } else {
      var d = document;
      var i = function () {
        i.c(arguments);
      };
      i.q = [];
      i.c = function (args) {
        i.q.push(args);
      };
      w.Intercom = i;
      var l = function () {
        var s = d.createElement('script');
        s.type = 'text/javascript';
        s.async = true;
        s.src =
          'https://widget.intercom.io/widget/' +
          process.env.VUE_APP_INTERCOM_APP_ID;
        var x = d.getElementsByTagName('script')[0];
        x.parentNode.insertBefore(s, x);
      };
      if (document.readyState === 'complete') {
        l();
      } else if (w.attachEvent) {
        w.attachEvent('onload', l);
      } else {
        w.addEventListener('load', l, false);
      }
    }
  })();
};

utils.routeForbidden = async (router, route, baseUrl = 'chantier') => {
  let url = '/listeBonLocation';
  const id = route.params.id;
  const idRide = route.params.idRide;
  const idItem = route.params.idItem;

  if (id && idRide && (baseUrl === 'chantier' || idItem)) {
    url = `/${baseUrl}/${id}/${idRide}`;

    if (idItem) {
      url += `/${idItem}`;
    }
  }

  const toast = await toastController.create({
    message: "Vous n'avez pas le droit d'accéder à cette page",
    duration: 3000,
    color: 'danger',
    position: 'top',
    cssClass: 'toast-rental-coupon',
  });
  await toast.present();

  router.replace(url);
};

/**
 * Used in both hour prestations and daily time reports
 */
const MAX_HOUR = 23;
const POSSIBLE_MINUTES = ['00', '15', '30', '45'];
utils.generatePickerColumns = (selectedHours, selectedMinutes) => {
  const hours = [];
  for (let i = 0; i <= MAX_HOUR; i++) {
    let value = i < 10 ? '0' : '';
    hours.push({ text: value + i, value: value + i });
  }

  const minutes = POSSIBLE_MINUTES.map(minute => {
    return { text: minute, value: minute };
  });

  return [
    {
      name: 'hours',
      options: hours,
      cssClass: 'picker-opts-right hydrated',
      optionsWidth: '34px',
      selectedIndex: selectedHours,
    },
    {
      name: 'minutes',
      options: minutes,
      cssClass: 'picker-opts-left hydrated',
      optionsWidth: '34px',
      selectedIndex: POSSIBLE_MINUTES.indexOf(selectedMinutes),
    },
  ];
};

utils.getAttachmentIconPath = extension => {
  let base = '/assets/icon/';

  if (extension.toLowerCase() === 'pdf') return base + 'pdf.svg';
  if (extension.toLowerCase() === 'png') return base + 'png.svg';
  if (['jpg', 'jpeg', 'bmp', 'webp'].includes(extension.toLowerCase()))
    return base + 'jpg.svg';
  if (['mp4', 'mkv', 'avi', 'mov', '3gp'].includes(extension.toLowerCase()))
    return base + 'mp4.svg';

  return base + 'doc.svg';
};

export default utils;
