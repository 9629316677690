import { getPlanning } from '@/services/driver/missionsOrders';

async function requestForRefresh() {
  if (document.hidden) {
    return;
  }
  let refresher = document.getElementById('refresher-detail');
  if (!refresher) {
    refresher = document.getElementById('refresher');
  } else {
    await getPlanning();
  }
  if (refresher) {
    refresher.click();
  }
}

async function refreshDataPlanning() {
  setInterval(requestForRefresh, 60000);
}

export { refreshDataPlanning };
