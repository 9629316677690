import axios from 'axios';
import { clearMission } from '@/services/driver/missionsOrders';
import utils from './utils/Utils';

const USER_KEY = 'locatUser';
const USER_REFRESH_KEY = 'locatUserRefresh';
let userData = {};
let errors = {};
let matomoId = null;

// Checks whether data about a user is still stored in the application
let storedUser = window.localStorage.getItem(USER_KEY);
if (storedUser) {
  userData = JSON.parse(storedUser);
}

const TOKEN_KEY = 'locatToken';
// We check if a user's token data is still stored in the application
let token = window.localStorage.getItem(TOKEN_KEY);

/**
 * Save user data into local storage
 */
function saveData() {
  window.localStorage.setItem(USER_KEY, JSON.stringify(userData));
  window.localStorage.setItem(TOKEN_KEY, token);
}

/**
 * Delete data stored into local storage
 */
function clearData() {
  window.localStorage.clear();
  userData = {};
  errors = {};
  token = null;
  clearMission();

  // Matomo reset
  const _paq = (window._paq = window._paq || []);
  _paq.push(['resetUserId']);
  _paq.push(['appendToTrackingUrl', 'new_visit=1']);
  matomoId = null;

  // Intercom reset
  if (window.Intercom) {
    window.Intercom('shutdown');
  }
}

/**
 * Fetch sanctum token for given credentials
 */
async function fetchToken(credentials) {
  // Returns the browser the user is on and its type
  credentials.device_name = navigator.userAgent;
  await axios
    .post('/sanctum/token', credentials)
    .then(response => {
      token = response.data;
      errors = {};
      saveData();
    })
    .catch(error => {
      if (error.response) {
        errors = error.response.data.errors;
      } else {
        errors = { email: ['Le réseau ne semble pas fonctionner.'] };
      }
    });

  return { errors };
}

/**
 * Fetch user info (email...) from Laravel server
 */
async function fetchInfo() {
  const response = await axios.get('/user', {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  userData = response.data;
  saveData();

  const now = new Date().getTime();
  localStorage.setItem(USER_REFRESH_KEY, now);
  return userData;
}

/**
 * Return user data from local storage
 */
async function getInfo() {
  const _paq = (window._paq = window._paq || []);
  const lastRefresh = localStorage.getItem(USER_REFRESH_KEY);
  const now = new Date().getTime();

  if (!token) {
    _paq.push(['resetUserId']);
    matomoId = null;
    return null;
  }

  // Fetch info only if needed
  // (if something is missing, or every 1 day)
  if (
    !userData ||
    !('email' in userData) ||
    !('user_hash' in userData) || // Related to Intercom
    !lastRefresh ||
    now - lastRefresh > 86400000 // One day in ms
  ) {
    await fetchInfo();
  }

  // Generate userId sent to Matomo
  let userId = null;
  if (userData && userData.agent && userData.agent.name) {
    userId = userData.agent.name;
    if (userData.company && userData.company.name) {
      userId = `${userData.company.name}|${userId}`;
    } else if (userData.company && userData.company.id) {
      userId = `${userData.company.id}|${userId}`;
    }
  }

  // Set the Matomo ID
  if (matomoId !== userId) {
    if (userId) {
      _paq.push(['setUserId', userId]);
    } else {
      _paq.push(['resetUserId']);
    }
    matomoId = userId;
  }

  return {
    token,
    userData,
  };
}

/**
 * Get auth token,
 * Then return the "params" to give to Axios
 */
async function getParams() {
  const { token } = await getInfo();

  return {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
}

/**
 * Check that a user is logged in
 */
function checkIfUser() {
  return !!(token && userData);
}

/**
 * Check that a user is logged in
 */
function getTypeUser() {
  if (
    userData &&
    userData.agent &&
    userData.agent.kind &&
    userData.agent.kind === 'driver'
  ) {
    return userData.agent.kind;
  }

  if (userData && userData.company && userData.company.kind) {
    return userData.company.kind;
  }

  return null;
}

/**
 * Returns the user's home page according to type
 */
function routeTypeUser() {
  if (
    userData &&
    userData.agent &&
    userData.agent.kind &&
    userData.agent.kind === 'driver'
  ) {
    return '/planning';
  }

  return '/connexion';
}

/**
 * Please note that as of now (2022-12-13), the Intercom icon is not shown
 * But Intercom is still initialized (when user is logged-in)
 *
 * And the only way to re-open Intercom messages is via settings page
 */
function setupIntercom(fullInfo) {
  if (!fullInfo || !fullInfo.userData || !fullInfo.userData.user_hash) {
    console.log('Intercom: User info or hash info not available.');
    return;
  }

  const userData = fullInfo.userData;

  window.intercomSettings = {
    app_id: process.env.VUE_APP_INTERCOM_APP_ID,
    alignment: 'right',

    // User data
    user_id: userData.id,
    user_hash: userData.user_hash,
    phone: userData.phone,

    // Agent data
    name: userData.agent.name,
    first_name: userData.agent.first_name,
    last_name: userData.agent.last_name,
    agent_kind: userData.agent.kind,

    // Company data
    company: {
      id: userData.company.id,
      name: userData.company.name,
    },
  };

  utils.loadIntercom();
}

export {
  fetchToken,
  getInfo,
  getParams,
  clearData,
  checkIfUser,
  getTypeUser,
  routeTypeUser,
  setupIntercom,
};
