/* eslint-disable no-console */

import { register } from 'register-service-worker';

/**
 * Inspired from these:
 * - https://github.com/vuejs/vue-cli/blob/dev/packages/%40vue/cli-plugin-pwa/generator/template/src/registerServiceWorker.js
 * - https://ionicframework.com/docs/v6/vue/pwa
 */
if (
  process.env.NODE_ENV === 'production' &&
  !JSON.parse(process.env.VUE_APP_DISABLE_SERVICE_WORKERS || 'false')
) {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready() {
      console.log(
        'App is being served from cache by a service worker.\n' +
          'For more details, visit https://goo.gl/AFskqB',
      );
    },
    registered() {
      console.log('Service worker has been registered.');
    },
    cached() {
      console.log('Content has been cached for offline use.');
    },
    updatefound() {
      console.log('New content is downloading.');
    },
    updated(registration) {
      // detect any change in the code
      // send custom event catched by mixins/update.js
      document.dispatchEvent(
        new CustomEvent('swUpdated', { detail: registration }),
      );
    },
    offline() {
      console.log(
        'No internet connection found. App is running in offline mode.',
      );
    },
    error(error) {
      console.error('Error during service worker registration:', error);
    },
  });
}
