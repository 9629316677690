import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import VueMatomo from 'vue-matomo';
import * as Sentry from '@sentry/vue';

import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonContent,
  IonicVue,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  isPlatform,
} from '@ionic/vue';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import '@/theme/variables.css';
import '@/theme/styleComponents.scss';
import '@/theme/fonts.scss';

import axios from 'axios';
import { arrowBackCircleOutline } from 'ionicons/icons';
import './registerServiceWorker';
import { getInfo as getUserInfo, setupIntercom } from './services/users';

const LAST_RELOAD_KEY = 'driveapp-reload';

/**
 * Catch rejected and uncatched promises
 *
 * The objective is mostly to catch "ChunkLoadError" events,
 * but not sure it works
 *
 * Please note, `reason` works only with Promises `unhandledrejection`
 *
 * Partial inspiration: https://github.com/dspurl/dsshop/blob/40110ce0958f447807397782db9aae123cc220a8/api/public/web/.nuxt/utils.js#L153
 */
window.addEventListener('unhandledrejection', function (e) {
  // No-refresh conditions
  const isNetworkError =
    e.reason && e.reason.message && e.reason.message === 'Network Error';
  const isAuthorizationError = // TODO: Test condition better
    e.reason && e.reason.message && e.reason.message === '401';

  // Variables to avoid infinite reloads
  const timeNow = Date.now();
  const previousReloadTime = parseInt(
    window.sessionStorage.getItem(LAST_RELOAD_KEY),
  );

  console.warn(
    "Yo bro, that's uncatched:",
    e.reason,
    'Info:',
    isNetworkError,
    isAuthorizationError,
    previousReloadTime,
    timeNow,
  );

  // Reload page to avoid very wrong states, expect when there is a no-refresh condition,
  // or when a reload has been done recently (less than a minute ago)
  if (
    !isNetworkError &&
    !isAuthorizationError &&
    (!previousReloadTime || previousReloadTime + 60000 < timeNow)
  ) {
    console.log('Voluntarly refresh page... (case 1)');
    window.sessionStorage.setItem(LAST_RELOAD_KEY, timeNow);

    setTimeout(() => {
      window.location.reload(true); // `true` is supposed to skip cache
    }, 2000);
  }
});

const getConfig = () => {
  let config = {};

  if (isPlatform('iphone')) {
    config = {
      ...config,
      backButtonText: 'Retour',
    };
  } else {
    config = {
      ...config,
      backButtonText: '',
      backButtonIcon: arrowBackCircleOutline,
    };
  }

  return config;
};

/**
 * Check for PWA update everytime a user re-enters the app
 */
document.addEventListener('visibilitychange', function () {
  if (document.visibilityState !== 'visible') return;
  if (!navigator.serviceWorker) return;

  // https://stackoverflow.com/a/36314598/4506790
  navigator.serviceWorker.getRegistrations().then(function (registrations) {
    registrations.forEach(function (registration) {
      // https://developer.mozilla.org/en-US/docs/Web/API/ServiceWorkerRegistration/update
      registration.update();
    });
  });
});

const app = createApp(App).use(IonicVue, getConfig()).use(router);

if (JSON.parse(process.env.VUE_APP_ENABLE_MATOMO || 'false')) {
  app.use(VueMatomo, {
    host: 'https://tam.locatiers.fr',
    siteId: 2,
    router,
    trackInitialView: true,
  });
}

// https://docs.sentry.io/platforms/javascript/guides/vue/
if (process.env.VUE_APP_SENTRY_DSN) {
  Sentry.init({
    app,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    // No tracing, too much requests...
    logErrors: true,
    // Change this if too much errors
    tracesSampleRate: 1.0,
  });
}

// Directly include the most used/common components
app.component('ion-refresher', IonRefresher);
app.component('ion-refresher-content', IonRefresherContent);
app.component('ion-page', IonPage);
app.component('ion-content', IonContent);
app.component('ion-card', IonCard);
app.component('ion-card-content', IonCardContent);
app.component('ion-card-header', IonCardHeader);
app.component('ion-card-title', IonCardTitle);

// https://www.intercom.com/help/en/articles/170-integrate-intercom-in-a-single-page-app#tell-intercom-when-your-data-or-url-changes
// https://github.com/AmazingDreams/vue-matomo/blob/3e6f16f37b248d0bf41f1fac81294f448a3c1ce6/src/index.js#L102
// With a setTimeout to ensure current has been changed
router.afterEach(() => {
  if (window.Intercom) {
    setTimeout(() => {
      window.Intercom('update', {
        last_request_at: parseInt(new Date().getTime() / 1000),
      });
    });
  }
});

// https://medium.com/thinkspecial/chunkloaderror-loading-chunk-failed-gopi-k-kancharla-413efbc21db2
router.onError(error => {
  console.warn('Yo bro, there was a router error!', error);

  if (
    /ChunkLoadError:.*failed./i.test(error.message) ||
    /Loading.*chunk.*failed./i.test(error.message)
  ) {
    console.log('Voluntarly refresh page... (case 2)');
    setTimeout(() => {
      window.location.reload(true);
    }, 333);
  }
});

router.isReady().then(() => {
  app.mount('#app');

  if (process.env.VUE_APP_INTERCOM_APP_ID) {
    getUserInfo().then(setupIntercom);
  }
});

// axios.defaults.withCredentials = true;

axios.defaults.baseURL = process.env.VUE_APP_API_URL;
